import { getSessionToken } from "@descope/react-sdk";
import analytics from './appAnalytics'
export const trackAnalytics = (eventName,data)=>{
    const session_id = getSessionToken();
    analytics.track(eventName,{...data,session_id:session_id});
}

export const initalizeAnalytics = (data)=>{
    // console.log("initalizeAnalytics")
    // analytics.track(data)
    // analytics.initalizeAnalytics(data)
}