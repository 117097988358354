import AppBreadcumb from "../common/components/app-breadcumb";

import { useEffect, useRef, useState } from 'react';
import "../common/assets/styles/common.scss";
import AppTable from "../common/components/app-table";
import AppTableCell from "../common/components/app-table-cell";
import { INITIAL_TABLE_SETTINGS, FIELDS_KEY, TABLE_LABELS, CARD_TYPE } from "../common/constants/table.constants"
import AllPAIsFilters from "./all-pais-filters";
import { useDispatch, useSelector } from "react-redux";
import "./all-pais.scss";
import { shareCar } from "../store/actions/allCars";
import { fetchPAIs } from "../store/actions/pais";
import ShareCarModal from "../common/components/share-car-modal";
import CarDetails from "../car-details";

const AllPAIs = () => {
	const CustomCell = cellProps => <AppTableCell {...cellProps} onRowClick={() => { }} type={CARD_TYPE.pais} />;
	const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);
	const showShareCarModal = useSelector((state) => state.allCars?.shareCar);
	const dispatch = useDispatch();
	const { paidData } = useSelector((state) => state.allCars);
	const { filters, data, totalElements } = useSelector((state) => state.pais);
	const isModal = useSelector((state) => state.allCars.isModal);
	const columns = [
		{
			field: FIELDS_KEY.CAR_BASIC_INFO,
			title: TABLE_LABELS.CAR_BASIC_INFO,
			cells: CustomCell,
			show: true,
			minWidth: 500,
			maxWidth: 700
		},
		{
			field: FIELDS_KEY.CAR_HIGHLIGHTS,
			title: TABLE_LABELS.CAR_HIGHLIGHTS,
			cells: CustomCell,
			show: true,
			minWidth: 125,
		},
		{
			title: TABLE_LABELS.DealersInfo,
			field: FIELDS_KEY.DealersInfo,
			cells: CustomCell,
			show: true,
			minWidth: 150,
		},
		{
			field: FIELDS_KEY.ACTION,
			title: TABLE_LABELS.ACTION,
			cells: CustomCell,
			show: true,
			minWidth: 200,
			maxWidth: 200
		}];
	const [tableColumns, setTableColumns] = useState([...columns]);
	const isInitialPageLoad = useRef(true);


	useEffect(() => {
		createTable();
		if (paidData && !isInitialPageLoad.current) {
			let prevPageNo = page?.skip;
			if (prevPageNo === INITIAL_TABLE_SETTINGS.skip) {
				if (paidData) {
					refreshPageData();
				}
			} else {
				setPage({
					skip: INITIAL_TABLE_SETTINGS.skip,
					take: INITIAL_TABLE_SETTINGS.take
				});
			}
		}
	}, [filters]);

	useEffect(() => {
		if (!isInitialPageLoad.current) {
			refreshPageData();
		}
	}, [page])

	useEffect(() => {
		createTable();
		if (paidData) {
			refreshPageData();
			isInitialPageLoad.current = false;
		}
	}, [paidData]);

	const createTable = () => {
		setTableColumns([...columns])
	}


	const refreshPageData = () => {
		dispatch(fetchPAIs({ sortBy: filters.sortBy?.id, page: page.skip, size: page.take, paId: paidData?.id }));
	}

	const handlePageChange = pageNumber => {
		setPage({
			skip: pageNumber.page.skip / pageNumber.page.take,
			take: pageNumber.page.take
		});

	}
	return (
		<>
			{isModal && <CarDetails type={CARD_TYPE.pais} dealerType={filters?.ocbLive?.id} />}
			<div className="page-details  all-pais-car page-details-layout">
				<div className="title-bar">
					<div className="recommended-cars">
						<AppBreadcumb />
					</div>
					<div className="view-all-pais app-link">
						<AllPAIsFilters />
					</div>


				</div>

				<div className="all-cars-table">
					<AppTable
						admin={true}
						data={data || []}
						skip={page.skip}
						take={page.take}
						headerData={[
							...tableColumns
						]}
						isHoverable={true}
						totalCount={totalElements}
						handlePageChange={handlePageChange}
						expandable={false}
						type={CARD_TYPE.pais}
					/>
				</div>
				{showShareCarModal && <ShareCarModal close={() => {
					dispatch(shareCar(false));
				}} />}
			</div>

		</>
	);
};
export default AllPAIs;
