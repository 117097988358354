import { CHALLAN_MODES } from "../../../utils/constants/values.constants";
import {
  challan_item_keys,
  ClosureDateCell,
  DisposeStatusCell,
} from "../components/challan-detail-revamp/viewModel";
// import { Link } from "react-router-dom";

// const NoticeNumberLinkCell = (props) => {
// 	const { dataItem } = props || {};
// 	const linkTo = `/operations/challans/disposers/${
// 		dataItem[challan_item_keys.CHALLAN_ID]
// 	}`;
// 	return (
// 		<td
// 			title={dataItem[challan_item_keys.NOTICE_NUMBER]}
// 			className="k-table-td ">
// 			{
// 				<Link
// 					to={linkTo}
// 					style={{ color: "#003E72", textDecoration: "underline" }}>
// 					{dataItem[challan_item_keys.NOTICE_NUMBER]}
// 				</Link>
// 			}
// 		</td>
// 	);
// };

export const dispose_columns = {
  [challan_item_keys.NOTICE_NUMBER]: {
    label: "Notice Number",
    field: challan_item_keys.NOTICE_NUMBER,
    // cell: NoticeNumberLinkCell,
  },
  [challan_item_keys.REG_NUMBER]: {
    label: "Reg Number",
    field: challan_item_keys.REG_NUMBER,
  },
  [challan_item_keys.CHALLAN_TYPE]: {
    label: "Challan Mode",
    field: challan_item_keys.CHALLAN_TYPE,
  },
  [challan_item_keys.SOURCE_CODE]: {
    label: "Source",
    field: challan_item_keys.SOURCE_CODE,
    filterable: true,
  },
  [challan_item_keys.REGION]: {
    label: "Region",
    field: challan_item_keys.REGION,
  },
  [challan_item_keys.ASSIGN_TO]: {
    label: "Assigned Disposer",
    field: challan_item_keys.ASSIGN_TO,
    hide: true,
  },
  [challan_item_keys.AGENT_NAME]: {
    label: "Assigned Agent",
    field: challan_item_keys.AGENT_NAME,
  },
  [challan_item_keys.PAYMENT_DATE]: {
    label: "Closure Date",
    cell: ClosureDateCell,
    field: challan_item_keys.PAYMENT_DATE,
  },
  [challan_item_keys.DELAY_REASON]: {
    label: "Delay Reason",
    field: challan_item_keys.DELAY_REASON,
    hide: true,
  },
  [challan_item_keys.STATUS]: {
    label: "Status",
    field: challan_item_keys.STATUS,
    cell: DisposeStatusCell,
  },
};

export const DD_DATA_SOURCES = {
  [challan_item_keys.CHALLAN_TYPE]: [...CHALLAN_MODES],
  [challan_item_keys.CHALLAN_OFFENSE_ID]: [],
  [challan_item_keys.STATUS]: [
    { value: "ASSIGNED", label: "Assigned" },
    { value: "SUBMITTED", label: "UnAssigned" },
    { value: "AGENT_ASSIGNED", label: "Agent Assigned" },
    { value: "PAID", label: "Paid" },
    { value: "UNABLE_TO_FULFILL", label: "Unable to Fulfill" },
    { value: "REJECTED", label: "Rejected" },
    { value: "PAYMENT_RAISED", label: "Payment Raised" },
    { value: "RAISE_REJECTED", label: "Raise Rejected" },
    { value: "RAISE_ACCEPTED", label: "Raise Accepted" },
    { value: "RAISE_AMENDED", label: "Amended" },
  ],
  [challan_item_keys.REGION]: [],
  [challan_item_keys.CHALLAN_COURT]: [],
  [challan_item_keys.AGENT_NAME]: [],
  [challan_item_keys.ASSIGN_TO]: [],
};

export const FF_REASON_TYPES = {
  REJECTED: "reject-reason",
  UNFULFILLED: "unfulfill-reason",
};
