import { DropDownList } from "@progress/kendo-react-dropdowns";

import { FIELDS_KEY, INITIAL_TABLE_SETTINGS, TABLE_LABELS } from "../common/constants/table.constants";
import AppTable from "../common/components/app-table";
import SearchBar from "./search-bar";
import './call-logs.scss'
import CallTableCell from "./call-table-cell";
import { useEffect, useRef, useState } from "react";
import { fetchCallLogsData } from "../store/actions/myDealers";
import { useDispatch, useSelector } from "react-redux";
import { AppLoader } from "../common/components/app-loader";
import { getLoginUserEmail } from "../../../utils/utils";
import { trackAnalytics } from "../common/services/analytics";
import { SegmentEvents } from "../common/constants/events.constants";
import { showToastMessage } from "../common/utils/utils";

const CallLogs = () => {

  const CustomCell = cellProps => {
    return <CallTableCell
      {...cellProps}
    />

  };

  const [callLogsData, setCallLogsData] = useState([]);
  const { paidData } = useSelector((state) => state.allCars);

  const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);
  const { isLoading } = useSelector((state) => state.appLoader);
  const { sortBy, currentSort } = useSelector((state) => state.callLogs);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('')
  const isInitialLoad = useRef(true);
  const email = getLoginUserEmail();

  const trackData = {
    email,
    searched_text: search,
  }


  useEffect(() => {
    if (!isInitialLoad.current) {
      if (search) {
        trackAnalytics(SegmentEvents.Viz_call_logs_searched_text, trackData);
      }
      setPage({
        skip: INITIAL_TABLE_SETTINGS.skip,
        take: INITIAL_TABLE_SETTINGS.take
      });
    }
  }, [search, sortBy]);

  useEffect(() => {
    if (!isInitialLoad.current) {
      fetchData({ page: page.skip, size: page.take, sort: sortBy });
    }

  }, [page])

  useEffect(() => {

    if (paidData) {
      fetchData({ page: page.skip, size: page.take, sort: sortBy });
      isInitialLoad.current = false;
    }


  }, [paidData?.id]);



  const handlePageChange = pageNumber => {
    setPage({
      skip: pageNumber.page.skip / pageNumber.page.take,
      take: pageNumber.page.take
    });
  }

  const fetchData = ({ page, size, sort }) => {

    const payload = {
      page,
      size,
      paId: paidData?.id,
      searchQuery: search,
      ...(currentSort === Object.keys(sort)[0]
        ? { sort: `conversationDuration,${sort.conversationTime}` }
        : currentSort === Object.keys(sort)[1]
          ? { sort: `startTime,${sort.callTime}` }
          : {}),
    };


    dispatch(fetchCallLogsData(payload)).then((resp) => {
      setCallLogsData(resp);
    }).catch((err) => {
      showToastMessage(err, false)
    });
  };

  const columns = [
    {
      field: FIELDS_KEY.Date,
      title: TABLE_LABELS.Date,
      cells: CustomCell,
      show: true,
      maxWidth: 110,
    },
    {
      field: FIELDS_KEY.Time,
      title: TABLE_LABELS.Time,
      cells: CustomCell,
      show: true,
      maxWidth: 80,
    },
    {
      title: TABLE_LABELS.ACTION,
      field: FIELDS_KEY.ACTION,
      cells: CustomCell,
      maxWidth: 80,
    },
    {
      title: TABLE_LABELS.DealersCode,
      field: FIELDS_KEY.DealersCode,
      cells: CustomCell,
      maxWidth: 80,
    },
    {
      title: TABLE_LABELS.Number,
      field: FIELDS_KEY.Number,
      cells: CustomCell,
      maxWidth: 110,
    },
    {
      title: TABLE_LABELS.DealerName,
      field: FIELDS_KEY.DealerName,
      cells: CustomCell,
      maxWidth: 120,
    },
    {
      field: FIELDS_KEY.CallType,
      title: TABLE_LABELS.CallType,
      cells: CustomCell,
      show: true,
      maxWidth: 110,
    },
    {
      field: FIELDS_KEY.OverallCallStatus,
      title: TABLE_LABELS.OverallCallStatus,
      cells: CustomCell,
      show: true,
      maxWidth: 110,
    },
    {
      field: FIELDS_KEY.HangupCause,
      title: TABLE_LABELS.HangupCause,
      cells: CustomCell,
      show: true,
      maxWidth: 120,
    },
    {
      field: FIELDS_KEY.CallerStatus,
      title: TABLE_LABELS.CallerStatus,
      cells: CustomCell,
      show: true,
      maxWidth: 110,
    },
    {
      field: FIELDS_KEY.OverallConversationTime,
      title: TABLE_LABELS.OverallConversationTime,
      cells: CustomCell,
      show: true,
      maxWidth: 140,
    },
    {
      field: FIELDS_KEY.CallerName,
      title: TABLE_LABELS.CallerName,
      cells: CustomCell,
      show: true,
      maxWidth: 110,
    },
    {
      field: FIELDS_KEY.Recording,
      title: TABLE_LABELS.Recording,
      cells: CustomCell,
      show: true,
      maxWidth: 110,
    },

  ];


  return (
    <div className="middleContent">
      <div className="call-log-header">
        <div className="search-container">
          <span className="call-log-text">Call Logs</span>
          <SearchBar setSearch={setSearch} />
        </div>
        <div className="dropdown-container">
          <DropDownList defaultValue="Today" />
        </div>
      </div>

      <div className="call-log-table">
        {isLoading ? <AppLoader />
          :
          <AppTable
            data={callLogsData.data}
            headerData={[
              ...columns
            ]}
            skip={page.skip}
            take={page.take}
            totalCount={callLogsData?.pagination?.totalElements}
            handlePageChange={handlePageChange}
            isHoverable
          />
        }
      </div>
    </div>

  )
}

export default CallLogs