import { getFFUserType } from "../utils/utils";

export default (api) => {

    const fetchPriceUpdateData = () => {
        return new Promise((resolve, reject) => {
            api
                .get(`/v1/product/pricing`, {
                    headers: { "user-type": `${getFFUserType()}` },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const updatePriceData = (type, payload) => {
        return new Promise((resolve, reject) => {
            const request = type === 'update' ? api.put : api.post;

            request(`/v1/product/pricing`, payload, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return {
        fetchPriceUpdateData,
        updatePriceData,
    };
};
