import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionToken } from "@descope/react-sdk";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { getVizPanelServiceData } from "./common/constants/env.constants";
import useCopyTracker from "./common/hooks/useCopyTracker";
import { getLoginUserEmail, getUserRoles } from "../../utils/utils";
import { CALL_EVENTS, SegmentEvents, WEB_SOCKET_ENDPOINT } from "./common/constants/events.constants";
import { getPAID } from "./store/actions/allCars";
import { getLastEventDetails, setCallEvent, setOngoingCallStatus, setEventSource, setCallingView } from "./store/actions/myDealers";
import { trackAnalytics } from "./common/services/analytics";
import AllCars from './all-cars'
import MyDealers from "./my-dealers";
import AllPAIs from "./all-pais"
import CallLogs from "./call-logs";
import { hasCallingAccess } from "./common/utils/pa-roles";

const VizPanelRoutes = () => {

  const { paidData } = useSelector((state) => state.allCars);
  const { currentCallEvent, isEventSourceCreated } = useSelector((state) => state.myDealers);

  const dispatch = useDispatch();
  const location = useLocation()
  const email = getLoginUserEmail();
  const apiMetaData = getVizPanelServiceData();
  const session_id = getSessionToken();
  const roles = getUserRoles();

  const handleCopy = (copiedText) => {
    let trackData = {
      email_id: email,
      copiedText: copiedText,
    };

    trackAnalytics(SegmentEvents.Viz_copied_text, trackData);
  };


  useCopyTracker(handleCopy);

  useEffect(() => {

    if (hasCallingAccess(roles)) {
      dispatch(setCallingView(true))
    }

    if (!paidData) {
      dispatch(getPAID(email));
    }

  }, [])






  useEffect(() => {
    let eventSource;

    if (paidData && !isEventSourceCreated) {

      dispatch(getLastEventDetails({ callerIdentity: paidData?.id })).then((resp) => {
        if (resp) {
          dispatch(setCallEvent(resp));
          dispatch(setOngoingCallStatus(true));
        }
      }
      ).catch((err) => {
        console.log(err);
      });

      eventSource = new EventSourcePolyfill(`${apiMetaData.url}${WEB_SOCKET_ENDPOINT}${paidData.id}`, {
        headers: {
          "x-auth-key": session_id
        }
      });

      eventSource.onopen = function () {
        console.log("Event source connected");
      };

      eventSource.onmessage = function (event) {
        console.log("Event received:", event.data);
        dispatch(setCallEvent(JSON.parse(event.data)));
      };

      eventSource.onerror = function (err) {
        eventSource.close();
      };


      dispatch(setEventSource(true));
    }


  }, [paidData])


  useEffect(() => {

    if (currentCallEvent) {

      const { event } = currentCallEvent;

      if (event === CALL_EVENTS.RECORDING_FINISHED || event === CALL_EVENTS.NO_ANSWER || event === CALL_EVENTS.DISCONNECTED) {
        dispatch(setOngoingCallStatus(false));
      }
    }

  }, [currentCallEvent])

  const renderComponent = () => {

    switch (location.pathname) {
      case "/viz/all-cars":
        return <AllCars />;
      case "/viz/call-logs":
        return <CallLogs />;
      case "/viz/my-dealers":
        return <MyDealers />;
      case "/viz/pais":
        return <AllPAIs />;
      default:
        return <div>404 Not Found</div>;
    }
  }


  return (
    <>
      {renderComponent()}
    </>
  );
};

export default VizPanelRoutes;
