export const DEFAULT_VALUE = 'N/A';

export const IRItemStatus = {
    Good: 'Good',
    Average: 'Average',
    Severe: 'Severe',
    Default: 'Default',
    NA: 'NA',
    Empty: 'Empty',
}

export const TAB_CAR_DETAILS_COLUMNS = [
    {
        label: "Odometer",
        value: ''
    }, {
        label: "Fuel Type",
        value: ''
    }, {
        label: "Color",
        value: ''
    }, {
        label: "Ownership",
        value: ''
    }, {
        label: "Insurance Expiry",
        value: ''
    }, {
        label: "Insurance Type",
        value: ''
    }, {
        label: "Registration city",
        value: ''
    }, {
        label: "Registration Number",
        value: ''
    },
];

export const CAR_TAG_NAMES = {
    CAR_SHARED: "CAR SHARED",
    SHARED_CAR_VIEWED: "SHARED CAR VIEWED",
    VIEWED_BY_DEALER: "VIEWED BY DEALER",
    BID_BY_DEALER: "BID BY DEALER"
};

