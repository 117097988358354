import React, { useEffect, useRef, useState } from 'react'

import { EVENT_CAR_SOURCE, FIELDS_KEY } from '../../common/constants/table.constants'
import CallIcon from '../../common/assets/images/callIcon'
import './call-table-cell.scss'
import PlayIcon from '../../common/assets/images/playIcon'
import PauseIcon from '../../common/assets/images/pauseIcon'
import AppChips from '../../common/components/app-chips'
import { Button } from '@progress/kendo-react-buttons'
import { callDealer, setCorrelationId, setOngoingCallStatus } from '../../store/actions/myDealers'
import { useDispatch, useSelector } from 'react-redux'
import { CALL_EVENTS } from '../../common/constants/events.constants'
import { convertMilliseconds, getCallEvent } from '../../common/utils/utils'



const CallTableCell = ({ dataItem, field }) => {

  const Date = () => {
    return (
      <span>
        {dataItem.date || 'N/A'}
      </span>
    )
  }

  const Time = () => {
    return (
      <span>
        {dataItem.time || 'N/A'}
      </span>
    )
  }

  const Action = () => {

    const dispatch = useDispatch();

    const { isCallOngoing } = useSelector((state) => state.myDealers);

    const handleCallDealer = () => {

      const { dealerId, paId, destinationNumber } = dataItem;
      dispatch(callDealer({ dealerId, paId, callingNumber: destinationNumber })).then((resp) => {
        dispatch(setCorrelationId(resp?.clientCorrelationId));
        getCallEvent(resp, EVENT_CAR_SOURCE.callLogs)
        dispatch(setOngoingCallStatus(true));
      }
      );

    }

    return (
      <div className='call-log-action'>
        <Button disabled={isCallOngoing} onClick={() => handleCallDealer()}>
          <CallIcon fill="#EF6E0B" />
          <span className='call-text'>Call</span>
        </Button>
      </div>
    )
  }

  const DealerNumber = () => {
    return (
      <span>
        {dataItem.destinationNumber || 'N/A'}
      </span>
    )
  }

  const DealerCode = () => {
    return (
      <span>
        {dataItem.dealerCode || 'N/A'}
      </span>
    )
  }

  const DealerName = () => {
    return (
      <span className='table-text'>
        {dataItem.dealerName || 'N/A'}
      </span >
    )
  }

  const CallType = () => {
    return (
      <span>
        {dataItem.callType || 'N/A'}
      </span>
    )
  }

  const OverallCallStatus = () => {

    const callStatus = dataItem?.overallCallStatus === CALL_EVENTS.ANSWERED ? 'Connected' : dataItem.overallCallStatus;
    return (
      <AppChips className={`${dataItem?.overallCallStatus === CALL_EVENTS.ANSWERED ? "primary-chips" : "missed-call-chip"}`} data={[callStatus]} />
    )
  }

  const HangupCause = () => {
    return (
      <span className='table-text'>
        {dataItem.destinationHangupCause || 'N/A'}
      </span>
    )
  }

  const CallerStatus = () => {
    return (
      <span>
        {dataItem.callerStatus || 'N/A'}
      </span>
    )
  }

  const OverallConversationTime = () => {
    return (
      <span>
        {convertMilliseconds(dataItem.conversationDuration) || 'N/A'}
      </span>
    )
  }

  const CallerName = () => {
    return (
      <span className='table-text'>
        {dataItem.callerName || 'N/A'}
      </span>
    )
  }

  const Recording = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);
    const audioUrl = dataItem?.recordingUrl;

    const togglePlayPause = () => {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };

    useEffect(() => {
      const audioElement = audioRef.current;

      const updateTime = () => {
        setCurrentTime(audioElement.currentTime);
      };

      const updateDuration = () => {
        setDuration(audioElement.duration);
      };

      const handleEnded = () => {
        audioElement.currentTime = 0;
        setCurrentTime(0);
        setIsPlaying(false);
      };

      if (audioElement) {
        audioElement.addEventListener('timeupdate', updateTime);
        audioElement.addEventListener('loadedmetadata', updateDuration);
        audioElement.addEventListener('ended', handleEnded);
      }

      return () => {
        if (audioElement) {
          audioElement.removeEventListener('timeupdate', updateTime);
          audioElement.removeEventListener('loadedmetadata', updateDuration);
          audioElement.removeEventListener('ended', handleEnded);
        }
      };
    }, [audioUrl]);

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    if (!audioUrl || dataItem?.overallCallStatus === CALL_EVENTS.MISSED || dataItem?.overallCallStatus === CALL_EVENTS.INITIATED) {
      return <span className='table-text'>N/A</span>;
    }

    return (
      <div className="recording-container">
        <div className="play-button" onClick={togglePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </div>
        <div className="progress-bar">
          <div className="progress" style={{ width: duration ? `${(currentTime / duration) * 100}%` : '0%' }}></div>
        </div>
        <div className="time-display">
          {formatTime(currentTime)} / {formatTime(duration)}
        </div>
        <audio ref={audioRef} src={audioUrl} />
      </div>
    );
  };



  return (
    <React.Fragment>
      <td className="k-table-td relative" onClick={() => { }}>
        {
          {
            [FIELDS_KEY.Date]: <Date />,
            [FIELDS_KEY.Time]: <Time />,
            [FIELDS_KEY.ACTION]: <Action />,
            [FIELDS_KEY.DealersCode]: <DealerCode />,
            [FIELDS_KEY.Number]: <DealerNumber />,
            [FIELDS_KEY.DealerName]: <DealerName />,
            [FIELDS_KEY.CallType]: <CallType />,
            [FIELDS_KEY.OverallCallStatus]: <OverallCallStatus />,
            [FIELDS_KEY.HangupCause]: <HangupCause />,
            [FIELDS_KEY.CallerStatus]: <CallerStatus />,
            [FIELDS_KEY.OverallConversationTime]: <OverallConversationTime />,
            [FIELDS_KEY.CallerName]: <CallerName />,
            [FIELDS_KEY.Recording]: <Recording />,

          }[field]
        }
      </td>
    </React.Fragment>
  )
}

export default CallTableCell