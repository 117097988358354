const currentEnv = process.env.REACT_APP_ENV;

export const getBuyBackServiceData = () => {
  const metaData = {
    STAGING: {
      url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/auction/api",
    },
    PRODUCTION:{
      url: "https://gateway.24c.in/auction/api",
    }

  };
  return metaData[currentEnv];
}