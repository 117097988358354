import { TYPE_CALLLOGS } from "../types/callLogs";

const initialState = {
  currentSort: null,
  sortBy: {
    conversationTime: 'asc',
    callTime: 'asc'
  }
};

const callLogs = (state = initialState, action) => {
  switch (action.type) {
    case TYPE_CALLLOGS.SET_FILTER_STATUS:
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          ...action.payload
        }
      }

    case TYPE_CALLLOGS.SET_CURRENT_SORT:
      return {
        ...state,
        currentSort: action.payload
      }


    default:
      return state;
  }
};

export default callLogs;
