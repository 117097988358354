import { useState, useEffect, useRef } from "react";
import carImage from "../../../common/assets/images/car-image.svg";
import { CARD_TYPE, CAR_DETAILS_TAB_LIST, EVENT_CAR_SOURCE } from "../../constants/table.constants";
import { Button } from "@progress/kendo-react-buttons";
import HoursLeft from "../hours-left";
import { ALL_CARS_OCB_LIVE_FILTER, MY_DEALERS_CARS_VIEW_FILTER } from "../../constants/filters.constants";
import { currencyFormat, getNumberSuffix, numberWithIndicator, percentageFormat, showToastMessage } from "../../utils/utils";
import { getCarDetails, getPaiCarDetails, setCarAppointmentId, setCarDetailsTab, setModal, updateCarsFilters } from "../../../store/actions/allCars";
import { useDispatch, useSelector } from "react-redux";
import { getVizPanelServiceData } from "../../constants/env.constants";
import { formatNumbers } from "../../utils/utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { copyToClipboard, dashifyYYYYMMDD, downloadPDF, getLoginUserEmail } from "../../../../../utils/utils";
import { InView } from "react-intersection-observer";
import { SegmentEvents, SegmentsEventTimeout } from "../../constants/events.constants";
import { trackAnalytics } from "../../services/analytics";
import CarTagIndicator from "./car-tag-indicators";
import { BID_TAG_COLOR, CAR_SHARED_TAG_COLOR, CAR_VIEWED_TAG_COLOR, DEFAULT_TAG_COLOR, SHARED_CAR_VIEWED_COLOR } from "../../constants/color.constants";
import CarViewedIcon from "../../assets/images/carViewedIcon";
import CarSharedIcon from "../../assets/images/carSharedIcon";
import SharedCarViewedIcon from "../../assets/images/sharedCarViewedIcon";
import BidIcon from "../../assets/images/bidIcon";
import { CAR_TAG_NAMES } from "../../constants/fields.constants";
import { useMonitorDomChange } from "../../hooks/useMonitorDomChange";

const CarCard = ({ data = {}, type, dealerType, appointmentState, logAnalytic = () => { }, isGrid = false, paiCar = false, carTag, ...props }) => {
	const [carHighlights, setCarHighlights] = useState([]);
	const [isAuctionEnded, setIsAuctionEnded] = useState(false);
	const { filters, paidData, isModal } = useSelector((state) => state.allCars);
	const apiMetaData = getVizPanelServiceData();
	const dispatch = useDispatch();
	const [filter, setFilter] = useState(null);
	const email = getLoginUserEmail()

	const { rtoCode, inspectionCity } = data || {};
	const { oldText, newText, targetedNode } = useMonitorDomChange('#text-element');

	useEffect(() => {

		if (oldText !== "") {
			const trackData = {
				oldText: oldText,
				newText: newText,
				auctionId: targetedNode?.auctionid || null,
				dealerId: targetedNode?.dealerid || null,
				appointmentId: targetedNode?.appointmentid,
				email_id: email,
				pa_id: paidData?.id,
				source: type == CARD_TYPE.dealers ? EVENT_CAR_SOURCE.dealers : type == CARD_TYPE.cars ? EVENT_CAR_SOURCE.cars : EVENT_CAR_SOURCE.pais,
				category: type == CARD_TYPE.dealers ? filters?.dealerCarCategory?.id : filters?.ocbLive?.id
			}

			trackAnalytics(SegmentEvents.Viz_changed_text, trackData);

		}

	}, [oldText])


	useEffect(() => {
		setIsAuctionEnded(false);
		setCarHighlights([
			'Engine ' + data?.engineRating,
			formatNumbers(data?.odometerReading) + ' km',
			numberWithIndicator(data?.ownerNumber) + ' owner',
			data?.fuelType
		]);
	}, [data, type]);

	const handleClick = () => {
		if (paiCar) dispatch(getPaiCarDetails(true));
		goToCarDetails(CAR_DETAILS_TAB_LIST[2].id);
		logAnalytic({ segmentEvent: SegmentEvents.Viz_car_details_page_opened });
		dispatch(setModal(true));
	};

	useEffect(() => {
		document.body.style.overflow = isModal ? 'hidden' : 'unset';
	}, [isModal]);

	const getFilterId = (type) => {
		switch (type) {
			case CARD_TYPE.dealers:
				return filters?.dealerCarCategory?.id;
			case CARD_TYPE.cars:
				return filters?.ocbLive?.id;
			case CARD_TYPE.pais:
				return 'pai';
			default:
				return null;
		}
	};

	const getCurrentFilter = (appointmentState) => {
		switch (appointmentState) {
			case 'IN_PROCURED':
				return 'procured';
			case 'IN_NEGO':
				return 'nego';
			case 'LIVE_AUCTION_RUNNING':
				return 'live';
			case 'OCB_RUNNING':
				return 'ocb';
			case 'AUCTION_OVER':
			case 'CAR_SOLD':
				return data?.requestedPrice ? 'ocb' : 'live';
			default:
				return null;
		}
	};

	useEffect(() => {
		if (filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id) {
			setFilter(getCurrentFilter(appointmentState));
		}
	}, [filters, appointmentState]);

	const goToCarDetails = (id) => {
		const filterId = getFilterId(type);
		dispatch(setCarAppointmentId(data?.appointmentId));
		dispatch(setCarDetailsTab(id));

		let payload = {
			appointmentCategory: filterId || filters?.ocbLive?.id,
			paId: paidData?.id
		};

		if (filterId === ALL_CARS_OCB_LIVE_FILTER[3].id) {
			payload = { ...payload, appointmentCategory: getCurrentFilter(appointmentState) };
			dispatch(updateCarsFilters({ anyCar: { id: getCurrentFilter(appointmentState), label: getCurrentFilter(appointmentState) } }));
		}

		if (props?.dealerId || data?.dealerId) {
			payload = { ...payload, dealerId: props?.dealerId || data?.dealerId };
		}

		dispatch(getCarDetails(data?.appointmentId, payload));
	};

	const handleCopyToClipboard = () => {

		let trackData = {
			copiedText: data?.appointmentId,
			email_id: email
		}

		copyToClipboard(data?.appointmentId)
			.then(() => {
				showToastMessage('Copied to clipboard', true, 1000)
				trackAnalytics(SegmentEvents.Viz_copied_text, trackData);
			})
			.catch(() => showToastMessage('Something went wrong', false, 1000));
	};

	const logRequired = useRef(false);

	const logCarDetails = (inView) => {
		logRequired.current = inView;
		if (inView && isGrid && !isModal) {
			setTimeout(() => {
				if (logRequired.current) {
					logRequired.current = false;
					logAnalytic();
				}
			}, SegmentsEventTimeout);
		}
	};

	function getCarTagDetails(tag) {
		switch (tag) {
			case CAR_TAG_NAMES.CAR_SHARED:
				return {
					icon: CarSharedIcon,
					backgroundColor: CAR_SHARED_TAG_COLOR,
					text: tag
				};
			case CAR_TAG_NAMES.SHARED_CAR_VIEWED:
				return {
					icon: SharedCarViewedIcon,
					backgroundColor: SHARED_CAR_VIEWED_COLOR,
					text: tag
				};
			case CAR_TAG_NAMES.VIEWED_BY_DEALER:
				return {
					icon: CarViewedIcon,
					backgroundColor: CAR_VIEWED_TAG_COLOR,
					text: tag
				};
			case CAR_TAG_NAMES.BID_BY_DEALER:
				return {
					icon: BidIcon,
					backgroundColor: BID_TAG_COLOR,
					text: tag
				};
			default:
				return {
					icon: CarSharedIcon,
					backgroundColor: DEFAULT_TAG_COLOR,
					text: tag
				};
		}
	}


	const maskString = (str) => (!str ? '' : '*'.repeat(str.length));
	const displayDealerName = !data?.hideDealerDetails ? data?.dealerName : maskString(data?.dealerName);
	const displayDealerCode = !data?.hideDealerDetails ? data?.dealerCode : maskString(data?.dealerCode);
	const tagDetails = carTag ? getCarTagDetails(carTag) : null;

	return (
		<>
			<InView as="div" className={`car-card-container ${isAuctionEnded ? 'card-auction-ended' : ''}`} threshold={0} onChange={(inView) => logCarDetails(inView)} delay={SegmentsEventTimeout}>
				{tagDetails && (
					<CarTagIndicator
						icon={tagDetails.icon}
						backgroundColor={tagDetails.backgroundColor}
						text={tagDetails.text}
					/>
				)}
				<div className="card-image-container">
					{filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id && type === CARD_TYPE.cars ? <span className="car-status">{appointmentState}</span> : null}
					<img
						onClick={handleClick}
						src={data?.shortGallery && data?.shortGallery.length > 0 ? `${apiMetaData.imagesUrl}/${data?.shortGallery[0]?.url}` : carImage}
						alt="cars24"
						className="car-card-img"
						onError={({ currentTarget }) => {
							currentTarget.onerror = null;
							currentTarget.src = carImage;
						}}
					/>
				</div>
				<div className="card-details">
					<div className="flex-between card-heading">
						<span className="card-title" onClick={handleClick}>{data?.year} {data?.make} {data?.model} {data?.variant}</span>
						<span className="hours-left"><HoursLeft hours={data?.endTime} auctionEnded={setIsAuctionEnded} /></span>
					</div>
					<div className="card-highlights">
						{carHighlights.map((ele, index) => (
							ele && (
								<span key={ele} className="highlight-chip">
									{ele}
									{index === 0 && <span className="word-space-left k-icon k-font-icon k-i-star app-rating-secondary"></span>}
								</span>
							)
						))}
					</div>
					<div>
						<div className="card-highlights flex-between">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<span className="card-sub-title">App. ID &nbsp;</span>
								<Tooltip position="top" className="call-tooltip">
									<span title="Click to copy" className="card-sub-title call cursor-pointer" onClick={handleCopyToClipboard}>
										{data?.appointmentId}
									</span>
								</Tooltip>
								<span className="k-icon k-font-icon k-i-file-pdf pdf-icon" onClick={() => downloadPDF(data?.carId)}></span>
							</div>
							<span className="car-sub-title">{rtoCode}{inspectionCity && ` | ${inspectionCity}`}</span>
						</div>
						{type === CARD_TYPE.cars && (filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[2].id || filter === ALL_CARS_OCB_LIVE_FILTER[2].id || filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[4].id || filter === ALL_CARS_OCB_LIVE_FILTER[4].id) ?
							<div className="flex-between">
								<div>
									<span className="card-sub-title">Dealer Name:&nbsp;</span>
									<span className="card-sub-title">{displayDealerName}</span>
								</div>
								<div>
									<span className="card-sub-title">Dealer Code:&nbsp;</span>
									<span className="card-sub-title">{displayDealerCode}</span>
								</div>
							</div> : null}
					</div>
					<hr className="hr-line" />
					<div id="text-element" data-auctionId={data?.auctionId} data-dealerId={data?.dealerId} data-appointmentId={data?.appointmentId}>
						{type == CARD_TYPE.pais &&
							<div className="detail-bid-block flex-between">
								<div>
									<div className="left-block">
										<span className="card-label">Previous Amount</span>
										<span className="card-value">{currencyFormat(data?.previousHighestBid)}</span>
									</div>
									<div className="right-block">
										<span className="card-label">Requested Price/TP</span>
										<span className="card-value">{percentageFormat(data?.requestedPriceTpRatio)}</span>
									</div>
								</div>
								<div>
									<div className="left-block">
										<span className="card-label">Dealer's Name</span>
										<span className="card-value">{data?.dealerDetails?.dealerName}</span>
									</div>
									<div className="right-block">
										<span className="card-label">Requested Price</span>
										<span className="card-value">{currencyFormat(data?.requestedPrice)}</span>
									</div>
								</div>
							</div>}

						{((type == CARD_TYPE.cars && (filters?.ocbLive?.id !== ALL_CARS_OCB_LIVE_FILTER[2].id && filter !== ALL_CARS_OCB_LIVE_FILTER[2].id && filters?.ocbLive?.id !== ALL_CARS_OCB_LIVE_FILTER[4].id && filter !== ALL_CARS_OCB_LIVE_FILTER[4].id)) || (type == CARD_TYPE.dealers && dealerType == MY_DEALERS_CARS_VIEW_FILTER[1].id)) &&
							<div className="detail-bid-block">
								<div className="flex-between">
									<div className="left-block">
										<span className="card-label">{filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[0]?.id || filter === ALL_CARS_OCB_LIVE_FILTER[0]?.id ? 'Highest Nego Price' : 'Highest Bid'}</span>
										<span className="card-value">{currencyFormat(data?.highestNegoPrice || data?.currentAuctionHighestBid)}</span>
									</div>
									<div className="right-block">
										{data?.auctionCount || ((type === CARD_TYPE.cars && (filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[1].id) || filter === ALL_CARS_OCB_LIVE_FILTER[1].id) || (type === CARD_TYPE.dealers && dealerType == MY_DEALERS_CARS_VIEW_FILTER[1].id)) ? <Button
											className='app-error-btn'
											fillMode={'solid'}
											size={'small'}
											onClick={() => { }}
										>
											<div>{data.auctionCount ? getNumberSuffix(data.auctionCount) : ''} Auction
											</div>
										</Button> : <span>
											{
												type == CARD_TYPE.cars ? <div className="right-block">
													<span className="card-label">Requested Price</span>
													<span className="card-value">{currencyFormat(data?.requestedPrice)}</span>
												</div> :
													<span></span>
											}
										</span>}
									</div>
								</div>
								<div className="flex-between">
									{type === CARD_TYPE.cars && (filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[0].id || ALL_CARS_OCB_LIVE_FILTER[0].id === filter) ?
										<div className="left-block">
											<span className="card-label">Req Price/TP</span>
											<span className="card-value">{percentageFormat(data?.requestedPriceTpRatio)}</span>
										</div>
										:
										<div className="left-block">
											<span className="card-label">Current HB/TP</span>
											<span className="card-value">{percentageFormat(data?.requestedPriceTpRatio || data?.highestBidTpRatio)}</span>
										</div>
									}
									<div className="right-block">
										<span className="card-label">Target Price (TP)</span>
										<span className="card-value">{currencyFormat(data?.targetPrice)}</span>
									</div>
								</div>
							</div>
						}
						{type == CARD_TYPE.dealers && dealerType == MY_DEALERS_CARS_VIEW_FILTER[0].id &&
							<div className="detail-bid-block">
								<div className="flex-between">
									<div className="left-block">
										<span className="card-label">Highest Nego Price</span>
										<span className="card-value">{currencyFormat(data?.highestNegoPrice)}</span>
									</div>
									<div className="right-block">
										<span className="card-label">Requested Price</span>
										<span className="card-value">{currencyFormat(data?.requestedPrice)}</span>
									</div>
								</div>
								<div className="flex-between">
									<div className="left-block">
										<span className="card-label">Req Price/TP</span>
										<span className="card-value">{percentageFormat(data?.requestedPriceTpRatio)}</span>
									</div>
									<div className="right-block">
										<span className="card-label">Target Price (TP)</span>
										<span className="card-value">{currencyFormat(data?.targetPrice)}</span>
									</div>
								</div>
							</div>
						}

						{(type === CARD_TYPE.dealers && dealerType === MY_DEALERS_CARS_VIEW_FILTER[2].id || dealerType === MY_DEALERS_CARS_VIEW_FILTER[3].id) || (type === CARD_TYPE.cars && (filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[2].id || filter === ALL_CARS_OCB_LIVE_FILTER[2].id || filters?.ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[4].id || filter === ALL_CARS_OCB_LIVE_FILTER[4].id)) ? (
							<div className="detail-bid-block">
								<div className="flex-between">
									{data?.winningPrice ? (
										<div className="left-block">
											<span className="card-label">Final Negotiated Price</span>
											<span className="card-value">{currencyFormat(data.winningPrice)}</span>
										</div>
									) : null}

									{data?.tokenDate ? (
										<div className="left-block">
											<span className="card-label">Token Date</span>
											<span className="card-value">{dashifyYYYYMMDD(data.tokenDate).slice(0, 10)}</span>
										</div>
									) : null}

									{data?.stockInDate ? (
										<div className="left-block">
											<span className="card-label">Stocked-in Date</span>
											<span className="card-value">{dashifyYYYYMMDD(data.stockInDate).slice(0, 10)}</span>
										</div>
									) : null}


								</div>
								<div className="flex-between">
									{data?.yardDetails ? (
										<div className="left-block">
											<span className="card-label">Yard Details</span>
											<span className="card-value">{data.yardDetails}</span>
										</div>
									) : null}
								</div>
							</div>
						) : null}

					</div>
				</div>
			</InView>
		</>
	);
};

export default CarCard;
