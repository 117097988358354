function ContactIcon(props) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 2.5v-2h10v2H10zm0 4v-2h10v2H10zm0 4v-2h10v2H10zm6.95 10c-2.083 0-4.142-.454-6.175-1.363-2.033-.908-3.883-2.195-5.55-3.862-1.667-1.667-2.954-3.517-3.862-5.55C.453 7.692 0 5.633 0 3.55c0-.3.1-.55.3-.75.2-.2.45-.3.75-.3H5.1a.93.93 0 01.625.237.914.914 0 01.325.563l.65 3.5c.033.267.025.492-.025.675-.05.183-.142.342-.275.475L3.975 10.4c.333.617.73 1.213 1.187 1.787.459.575.963 1.13 1.513 1.663.517.517 1.058.996 1.625 1.438.567.441 1.167.845 1.8 1.212l2.35-2.35a1.4 1.4 0 01.588-.338 1.61 1.61 0 01.712-.062l3.45.7c.233.067.425.188.575.363A.88.88 0 0118 15.4v4.05c0 .3-.1.55-.3.75-.2.2-.45.3-.75.3zM3.025 8.5l1.65-1.65L4.25 4.5H2.025c.083.683.2 1.358.35 2.025.15.667.367 1.325.65 1.975zm8.95 8.95c.65.283 1.313.508 1.988.675.675.167 1.354.275 2.037.325v-2.2l-2.35-.475-1.675 1.675z"
        fill="#EF6E0B"
      />
    </svg>
  )
}

export default ContactIcon
