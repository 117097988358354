import { useEffect, useRef, useState, } from 'react';

import "../common/assets/styles/common.scss";
import AppTable from "../common/components/app-table";
import AppTableCell from "../common/components/app-table-cell";
import { INITIAL_TABLE_SETTINGS, FIELDS_KEY, TABLE_LABELS, CARD_TYPE, EVENT_CAR_SOURCE } from "../common/constants/table.constants"
import "./my-dealers.scss";
import MyDealersFilters from "./my-dealers-filters"
import { useDispatch, useSelector } from "react-redux";
import { callDealer, fetchMyDealers, setDealerFilterQueryParam, setCorrelationId, setOngoingCallStatus, setSidebarDealerFilterPayloads, setSidebarDealerFilterApplied, setDealerFilterStatus } from "../store/actions/myDealers";
import { shareCar } from "../store/actions/allCars";
import ShareCarModal from "../common/components/share-car-modal";
import ConfirmContactedModal from "../common/components/confirm-contacted-modal";
import OnGoingPais from "../all-pais/on-going-pais";
import { DEFAULT_VALUE } from "../common/constants/fields.constants";
import { AppLoader } from '../common/components/app-loader';
import TablePageInfo from '../common/components/table-page-info';
import { formatNumbers, queryStringToObject, getCallEvent } from '../common/utils/utils';
import ContactCell from '../common/components/contact-cell';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const MyDealers = () => {
	const CustomCell = cellProps => <AppTableCell {...cellProps} onRowClick={() => { }}
		type={CARD_TYPE.dealers} />;
	const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);
	const [searchValue, setSearchValue] = useState('');
	const dispatch = useDispatch();
	const showShareCarModal = useSelector((state) => state.allCars?.shareCar);
	const dealers = useSelector((state) => state.myDealers);
	const { paidData } = useSelector((state) => state.allCars);
	const { filters, isCallOngoing, isLoading, dealerFilterQueryParam, markAsContactedConfirm, callingView, refreshRequired } = useSelector((state) => state.myDealers);
	const isInitialPageLoad = useRef(true);
	const tableRef = useRef(null);
	const [selectedContact, setSelectedContact] = useState(null);
	const location = useLocation();
	const history = useHistory();
	const params = new URLSearchParams(window.location.search);

	useEffect(() => {
		if (isInitialPageLoad.current) return;

		const params = new URLSearchParams({ page: 1 });
		const { pathname } = location;

		history.push({ pathname, search: params.toString() });

		dispatch(setDealerFilterQueryParam(params.toString()));
		dispatch(setSidebarDealerFilterPayloads(null));
		dispatch(setSidebarDealerFilterApplied(null));
		dispatch(setDealerFilterStatus(false));

		if (page?.skip === INITIAL_TABLE_SETTINGS.skip) {
			if (paidData) refreshPageData();
		} else {
			setPage({
				skip: INITIAL_TABLE_SETTINGS.skip,
				take: INITIAL_TABLE_SETTINGS.take,
			});
		}

	}, [filters, searchValue,refreshRequired]);


	useEffect(() => {
		if (!isInitialPageLoad.current) {
			refreshPageData();
		}
	}, [page])

	useEffect(() => {

		if (paidData) {
			if (dealerFilterQueryParam) {

				const queryParams = queryStringToObject(dealerFilterQueryParam);

				setPage({
					skip: (queryParams['page'] ? queryParams['page'] : 1) - 1,
					take: INITIAL_TABLE_SETTINGS.take
				});

				history.push({
					pathname: location.pathname,
					search: `?${dealerFilterQueryParam}`
				})

			}
			else {

				const page = params.get('page') || 1;
				const paramLength = Array.from(params.entries()).length;

				setPage({
					skip: page - 1,
					take: INITIAL_TABLE_SETTINGS.take
				});

				history.push({
					pathname: location.pathname,
					search: paramLength > 0 ? params.toString() : `?page=${page}`
				})

				dispatch(setDealerFilterQueryParam(params.toString()));

			}

			isInitialPageLoad.current = false;

		}


	}, [paidData]);


	const SafeCells = safeCellProps => {
		return (
			<td className='contact-cell' onClick={() => { }}>
				{safeCellProps.children}
			</td>
		);
	};

	const tableColumns = [
		{
			field: FIELDS_KEY.CompanyName,
			title: TABLE_LABELS.CompanyName,
			cells: CustomCell,
			minWidth: 180,

		},
		{
			field: FIELDS_KEY.DealerName,
			title: TABLE_LABELS.DealerName,
			cells: CustomCell,
			maxWidth: 120
		},
		{
			field: FIELDS_KEY.DealersCode,
			title: TABLE_LABELS.DealersCode,
			cells: CustomCell,
			maxWidth: 80
		},
		{
			field: FIELDS_KEY.CarAge,
			title: TABLE_LABELS.CarAge,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.carAge}</div>
				</SafeCells>
			),
			maxWidth: 100
		},
		{
			field: FIELDS_KEY.KMDriven,
			title: TABLE_LABELS.KMDriven,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{formatNumbers(dataItem?.dealerPreference?.kmDriven)}</div>
				</SafeCells>
			),
			maxWidth: 100
		},
		{
			title: TABLE_LABELS.BodyType,
			field: FIELDS_KEY.BodyType,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.bodyType}</div>
				</SafeCells>
			),
			maxWidth: 120
		},
		{
			field: FIELDS_KEY.ASP,
			title: TABLE_LABELS.ASP,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.asp}</div>
				</SafeCells>
			),
			maxWidth: 100
		},
		{
			field: FIELDS_KEY.FuelType,
			title: TABLE_LABELS.FuelType,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.fuelType}</div>
				</SafeCells>
			),
			maxWidth: 110
		},
		{
			field: FIELDS_KEY.OwnerCount,
			title: TABLE_LABELS.OwnerCount,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem?.dealerPreference?.ownerCount}</div>
				</SafeCells>
			),
			maxWidth: 80
		},
		{
			field: FIELDS_KEY.TotalStockedIn,
			title: TABLE_LABELS.TotalStockedIn,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div className='w-100 flex total-stocked'>
						<div className='average'>
							{dataItem?.stockedIn?.average !== null ? dataItem?.stockedIn?.average : DEFAULT_VALUE}
						</div>
						<div className='current-month'>
							{dataItem?.stockedIn?.currentMonth !== null ? dataItem?.stockedIn?.currentMonth : DEFAULT_VALUE}
						</div>
					</div>
				</SafeCells>
			),
			maxWidth: 180
		},
		...(callingView ? [{
			title: TABLE_LABELS.ContactPerson,
			field: FIELDS_KEY.ContactPerson,
			cells: ({ dataItem }) => {
				return <SafeCells data={dataItem}>
					<ContactCell dealerName={dataItem?.primaryPersonName} tableRef={tableRef} dealerId={dataItem?.dealerId} isCallOngoing={isCallOngoing} contacts={dataItem?.contactNumbers} setSelectedContact={setSelectedContact} selectedContact={selectedContact} handleCallDealer={handleCallDealer} />
				</SafeCells>
			},
			maxWidth: 150,
		}] : [])

	];

	const handlePageChange = pageNumber => {
		const params = new URLSearchParams();

		params.set('page', (pageNumber.page.skip / pageNumber.page.take) + 1);

		dispatch(setDealerFilterQueryParam(params.toString()));
		dispatch(setSidebarDealerFilterPayloads(null));
		dispatch(setSidebarDealerFilterApplied(null));
		dispatch(setDealerFilterStatus(false));


		history.push({
			pathname: location.pathname,
			search: params.toString()
		});


		setPage({
			skip: pageNumber.page.skip / pageNumber.page.take,
			take: pageNumber.page.take
		});


	}

	const refreshPageData = () => {
		dispatch(fetchMyDealers({
			sortBy: filters.sortBy?.id,
			filterBy: filters.dealerType?.id,
			page: page.skip,
			size: page.take,
			paId: paidData?.id,
			searchQuery: searchValue
		}));
	}

	const handleCallDealer = (dealerId, contacts) => {
		const paId = paidData?.id;
		const defaultContact = contacts.find(contact => contact.isDefault);

		const callingNumber = defaultContact ? defaultContact.number : contacts[0]?.number;
		dispatch(callDealer({ dealerId, paId, callingNumber })).then((resp) => {
			getCallEvent(resp, EVENT_CAR_SOURCE.dealers)
			dispatch(setCorrelationId(resp?.clientCorrelationId));
			dispatch(setOngoingCallStatus(true));

		}
		);


	}

	return (
		<>
			<div className="page-details all-cars page-details-layout">
				<div className="flex-column">
					<OnGoingPais type={CARD_TYPE.dealers} />
					<div className="title-bar">
						<div className="title-text">MY DEALERS</div>
						<div className="view-all-pais">
							<TablePageInfo currentPage={page.skip} total={dealers?.totalElements} take={page.take} />
							<MyDealersFilters onSearchChange={setSearchValue} />
						</div>
					</div>
				</div>
				<div className="app-expandable-table app-table" ref={tableRef} id="dealer-table">
					<AppTable
						admin={true}
						data={dealers?.data || []}
						skip={page.skip}
						take={page.take}
						headerData={tableColumns}
						isHoverable={true}
						totalCount={dealers?.totalElements}
						handlePageChange={handlePageChange}
						expandable
						wrapperClassName={'dealer-table-wrapper'}
						type={CARD_TYPE.dealers}
						scrollTo={"dealer-table"}
					/>
				</div>
			</div>

			{showShareCarModal && <ShareCarModal close={() => dispatch(shareCar(false))} />}
			{markAsContactedConfirm?.paId && <ConfirmContactedModal />}
			{isLoading && <AppLoader />}
		</>
	);
};

export default MyDealers;
