import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
import { getUserRoles } from "../../../../utils/utils";
import { MY_DEALERS_CARS_VIEW_FILTER, MY_DEALERS_CARS_SORT_FILTER, ALL_CARS_OCB_LIVE_FILTER, ALL_CARS_SORT_BY_FILTER } from "../../common/constants/filters.constants";
import { TYPE_ALLCARS } from "../types";

const INITIAL_STATE = {
  isLoading: false,
  isModal: false,
  isPaiCar: false,
  data: [],
  page: 0,
  totalElements: 0,
  pageSize: 0,
  totalPages: 0,
  error: {},
  sortBy: "",
  isFilterApplied: false,
  checkedCenterItems: [],
  filters: {
    ocbLive: getUserRoles().includes(USER_ROLE_KEYS.C2B_VIZ_PANEL_NO_LIVE_FEED) ? ALL_CARS_OCB_LIVE_FILTER[2] : ALL_CARS_OCB_LIVE_FILTER[0],
    sortBy: ALL_CARS_SORT_BY_FILTER[0],
    dealerCarCategory: MY_DEALERS_CARS_VIEW_FILTER[0],
    dealerCarSort: MY_DEALERS_CARS_SORT_FILTER[0],
    searchAllCarQuery: "",
    anyCar: {}
  },
  shareCar: false,
  filterQueryParam: '',
  selectedTab: 1,
  paidData: null,
  carDetails: null,
  appointmentId: null,
  sidebarFilters: null,
  sidebarFiltersPayload: null,
  sidebarFiltersApplied: null
};

const AllCars = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_ALLCARS.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case TYPE_ALLCARS.UPDATE_PAID:
      return {
        ...state,
        paidData: action.payload,
      };

    case TYPE_ALLCARS.SET_MODAL:
      return {
        ...state,
        isModal: action.payload
      }

    case TYPE_ALLCARS.SET_PAI_CAR_DETAIL:
      return {
        ...state,
        isPaiCar: action.payload
      }

    case TYPE_ALLCARS.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    case TYPE_ALLCARS.FETCH_ALLCARS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        error: {},
      };
    case TYPE_ALLCARS.FETCH_ALLCARS_FAILURE:
      return {
        ...state,
        data: [],
        totalCount: 0,
        numberOfElements: 0,
        error: action.payload,
      };
    case TYPE_ALLCARS.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        },

      };
    case TYPE_ALLCARS.SHARE_CAR:
      return {
        ...state,
        shareCar: action.payload
      };
    case TYPE_ALLCARS.SET_CAR_DETAILS_TAB:
      return {
        ...state,
        selectedTab: action.payload
      };
    case TYPE_ALLCARS.CAR_DETAILS:
      return {
        ...state,
        carDetails: action.payload.data
      };
    case TYPE_ALLCARS.SET_CAR_APPOINTMENT_ID:
      return {
        ...state,
        appointmentId: action.payload
      };
    case TYPE_ALLCARS.FETCH_SIDE_BAR_FILTERS:
      return {
        ...state,
        sidebarFilters: action.payload
      };
    case TYPE_ALLCARS.SET_SIDEBAR_FILTERS_PAYLOAD:
      return {
        ...state,
        sidebarFiltersPayload: action.payload
      };
    case TYPE_ALLCARS.SET_SIDEBAR_FILTERS_APPLIED:
      return {
        ...state,
        sidebarFiltersApplied: action.payload
      };
    case TYPE_ALLCARS.CLEAR_CAR_DATA:
      return {
        ...state,
        data: []
      };

    case TYPE_ALLCARS.SET_FILTER_QUERY_PARAM:
      return {
        ...state,
        filterQueryParam: action.payload
      };

    case TYPE_ALLCARS.SET_FILTER_STATUS:
      return {
        ...state,
        isFilterApplied: action.payload
      };

    case TYPE_ALLCARS.SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedCenterItems: action.payload
      };

    default:
      return state;
  }
};
export default AllCars;
