import React, { useEffect, useState } from 'react'
import { VAS_PRICE_STATUS_DATA, vas_price_detail_form } from './viewModal';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { FormComboBox, FormInput, FormNumericTextBox } from '../../../../../components';
import { Button } from '@progress/kendo-react-buttons';
import { VAS_PRICE_UPDATE_SCREEN, vas_price_update_column_keys } from '../../viewModal';
import { VasService } from '../../../../../services';
import { showToastMessage } from '../../../../../utils/utils';

const PriceDetail = ({ dataRow, setScreen, setData, data }) => {

    const [formData, setFormData] = useState(vas_price_detail_form);
    const isUpdate = !!Object.keys(dataRow).length;

    useEffect(() => {
        if (isUpdate) {
            const updatedFormData = Object.fromEntries(
                Object.entries(dataRow).map(([key, value]) => [
                    key,
                    {
                        ...vas_price_detail_form[key],
                        value: value
                    }
                ])
            );
            setFormData(updatedFormData);
        } else {
            setFormData(vas_price_detail_form);
        }
    }, [dataRow]);

    const handleChange = (e) => {
        const { value } = e;
        const { id } = e.target.props || {};
        const key = id || e.target.name;

        let parsedValue = key === vas_price_update_column_keys.STATUS ? value?.value : value;
        let updatedPopupData = {
            ...formData,
            [key]: {
                ...formData[key],
                value: parsedValue
            },
        };
        setFormData(updatedPopupData);
    };

    const handleSubmit = () => {
        if (formData[vas_price_update_column_keys.CONTROL_PRICE]?.value === undefined || formData[vas_price_update_column_keys.CONTROL_PRICE]?.value === null) {
            showToastMessage("Enter Control Price", false);
            return;
        }
        if (formData[vas_price_update_column_keys.VARIANT_PRICE]?.value === undefined || formData[vas_price_update_column_keys.VARIANT_PRICE]?.value === null) {
            showToastMessage("Enter Variant Price", false);
            return;
        }
        if (formData[vas_price_update_column_keys.VARIANT_TWO_PRICE]?.value === undefined || formData[vas_price_update_column_keys.VARIANT_TWO_PRICE]?.value === null) {
            showToastMessage("Enter Variant Two Price", false);
            return;
        }
        const payload = Object.values(vas_price_update_column_keys).reduce((acc, key) => {
            if (isUpdate || key !== vas_price_update_column_keys.ID) {
                acc[key] = formData[key] ? formData[key].value : null;
            }
            return acc;
        }, {});

        VasService.updatePriceData(isUpdate ? "update" : "create", payload).then((res) => {
            showToastMessage(isUpdate ? "Price Updated" : "Price Added");
            const filteredData = Object.keys(res).reduce((acc, key) => {
                if (!['createdAt', 'updatedAt'].includes(key)) {
                    acc[key] = res[key];
                }
                return acc;
            }, {});

            if (isUpdate) {
                const updatedData = data.map(row => {
                    if (row?.[vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER] === res?.[vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER]) {
                        return { ...row, ...filteredData };
                    }
                    return row;
                });
                setData(updatedData);
            } else {
                setData([...data, filteredData]);
            }
            setScreen(VAS_PRICE_UPDATE_SCREEN.PRICE_LISTING);
        }).catch((err) => showToastMessage(err?.message, false));
    }

    return (
        <>
            <GridLayout
                gap={{
                    rows: "70px",
                    cols: 10,
                }}>
                <GridLayoutItem row={1} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Button themeColor={"primary"} fillMode={"outline"} onClick={() => {
                        setScreen(VAS_PRICE_UPDATE_SCREEN.PRICE_LISTING);
                    }}>Back</Button>
                    <Button themeColor={"primary"} onClick={handleSubmit}>{isUpdate ? "Update" : "Save"}</Button>
                </GridLayoutItem>
                <GridLayoutItem row={2}>
                    <GridLayout
                        gap={{
                            rows: "20px",
                            cols: 40,
                        }}>
                        {Object.keys(vas_price_detail_form)?.map((item, index) => (
                            <GridLayoutItem col={(index % 2) + 1}>
                                {formData[item]?.field === vas_price_update_column_keys.STATUS ?
                                    <FormComboBox
                                        id={item}
                                        key={item}
                                        onChange={handleChange}
                                        data={VAS_PRICE_STATUS_DATA}
                                        textField="label"
                                        dataItemKey="value"
                                        value={{ label: formData[item]?.value, value: formData[item]?.value }}
                                        isDisplayLabel={true}
                                        label={formData[item]?.label}
                                    /> :
                                    [vas_price_update_column_keys.CONTROL_PRICE, vas_price_update_column_keys.VARIANT_PRICE,
                                    vas_price_update_column_keys.VARIANT_TWO_PRICE, vas_price_update_column_keys.DISCOUNT].includes(formData[item]?.field)
                                        ?

                                        <FormNumericTextBox
                                            label={formData[item]?.label}
                                            id={item}
                                            key={item}
                                            onChange={handleChange}
                                            value={formData[item]?.value}
                                            min={0}
                                        />
                                        :
                                        <FormInput
                                            label={formData[item]?.label}
                                            id={item}
                                            key={item}
                                            onChange={handleChange}
                                            value={formData[item]?.value || ""}
                                            disabled={item === vas_price_update_column_keys.STATUS}
                                        />
                                }

                            </GridLayoutItem>
                        ))}
                    </GridLayout>
                </GridLayoutItem>
            </GridLayout>
        </>

    )
}
export default PriceDetail
