import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import OpsChallanReducer from "./ops-challan";
import opsChallanItemReducer from "./ops-challan-item";
import DealerProcurementReducer from "./dealer-procurement";
import CentreReducer from "./centre";
import dealerPayoutsReducer from "./dealer-payouts";
import prAutomationReducer from "./pr-automation";
import lmsReducer from "./lms";
import ServiceHistoryReducer from "./service-history";
import OpsVerificationReducer from "./ops-verification";
import retailReducer from "./retail-consumer";
import paisReducer from "../../views/viz-panel/store/reducer/pais";
import myDealersReducer from "../../views/viz-panel/store/reducer/myDealers";
import allCarsReducer from "../../views/viz-panel/store/reducer/allCars";
import breadCumbReducer from "../../views/viz-panel/store/reducer/breadCumb";
import appLoaderReducer from "../../views/viz-panel/store/reducer/appLoader";
import vehicleInspectionReducer from './vehicle-inspection';
import buyBackReducer from '../../views/dealer-app/store/reducer/buyback'
import appAnalyticsReducer from '../../views/viz-panel/store/reducer/appAnalytics';

import callLogsReducer from "../../views/viz-panel/store/reducer/callLogs";

export default combineReducers({
  form: formReducer,
  opsChallan: OpsChallanReducer,
  opsChallanItem: opsChallanItemReducer,
  dealerProcurement: DealerProcurementReducer,
  Centre: CentreReducer,
  dealerPayouts: dealerPayoutsReducer,
  prAutomation: prAutomationReducer,
  lms: lmsReducer,
  serviceHistory: ServiceHistoryReducer,
  opsVerification: OpsVerificationReducer,
  retailConsumers: retailReducer,
  pais: paisReducer,
  allCars: allCarsReducer,
  myDealers: myDealersReducer,
  breadCumb: breadCumbReducer,
  appLoader: appLoaderReducer,
  vehicleInspection: vehicleInspectionReducer,
  buyBackData: buyBackReducer,
  appAnalytics:  appAnalyticsReducer,
  callLogs: callLogsReducer
});
