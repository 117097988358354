import { TYPE_ALLCARS, TYPE_LOADER } from "../types";
import { vizPanelService } from "../../services";
import { getReqParams, showToastMessage } from "../../common/utils/utils";

export const shareCar =
  (data) =>
    (dispatch) => {
      dispatch({ type: TYPE_ALLCARS.SHARE_CAR, payload: data });

    };

export const setCarDetailsTab =
  (data) =>
    (dispatch) => {
      sessionStorage.setItem('currentTab', data)
      dispatch({ type: TYPE_ALLCARS.SET_CAR_DETAILS_TAB, payload: data });

    };

export const setSidebarFilterPayloads =
  (data) =>
    (dispatch) => {
      dispatch({ type: TYPE_ALLCARS.SET_SIDEBAR_FILTERS_PAYLOAD, payload: data });

    };

export const setSidebarFilteApplied =
  (data) =>
    (dispatch) => {
      dispatch({ type: TYPE_ALLCARS.SET_SIDEBAR_FILTERS_APPLIED, payload: data });

    };

export const setCenterCheckedItems =
  (data) =>
    (dispatch) => {
      dispatch({ type: TYPE_ALLCARS.SET_CHECKED_ITEMS, payload: data });

    };

export const clearAllCars =
  () =>
    (dispatch) => {
      dispatch({ type: TYPE_ALLCARS.CLEAR_CAR_DATA });
    };

export const fetchAllCars = (params = {}, filters = null) => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });
  dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_START });

  let reqParams = getReqParams(params);

  vizPanelService.getAllCars(reqParams, filters)
    .then((resp) => {
      dispatch({
        type: TYPE_ALLCARS.FETCH_ALLCARS_SUCCESS,
        payload: {
          data: resp.data,
          page: resp.pagination.page,
          pageSize: resp.pagination.pageSize,
          totalElements: resp.pagination.totalElements,
          totalPages: resp.pagination.totalPages,
        },
      });
    })
    .catch((err) => {
      showToastMessage(err?.error || err?.message, false);
      dispatch({
        type: TYPE_ALLCARS.FETCH_ALLCARS_FAILURE,
        payload: err,
      });
    })
    .finally(() => {
      dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
    });
};


export const fetchDealerCars = (dealerId, params = {}, filters = null) => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });
  dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_START });

  let reqParams = getReqParams(params);

  vizPanelService.getDealerCars(dealerId, reqParams, filters)
    .then((resp) => {
      dispatch({
        type: TYPE_ALLCARS.FETCH_ALLCARS_SUCCESS,
        payload: {
          data: resp.data,
          page: resp.pagination.page,
          pageSize: resp.pagination.pageSize,
          totalElements: resp.pagination.totalElements,
          totalPages: resp.pagination.totalPages,
        },
      });
    })
    .catch((err) => {
      showToastMessage(err?.error || err?.message, false);
      dispatch({
        type: TYPE_ALLCARS.FETCH_ALLCARS_FAILURE,
        payload: err,
      });
    })
    .finally(() => {
      dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
    });
};

export const setFilterStatus = (status) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.SET_FILTER_STATUS, payload: status })
}


export const updateCarsFilters = (filter) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.UPDATE_FILTERS, payload: filter });
}
export const getCarDetails =
  (appointmentId, params = {}) =>
    (dispatch) => {
      dispatch({ type: TYPE_LOADER.SHOW_LOADER });
      let reqParams = getReqParams(params);
      vizPanelService.getCarDetails(appointmentId, reqParams)
        .then((resp) => {
          dispatch({
            type: TYPE_ALLCARS.CAR_DETAILS,
            payload: {
              data: resp,
            },
          });
          dispatch({ type: TYPE_LOADER.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: TYPE_LOADER.HIDE_LOADER });
          showToastMessage(err?.error || err?.message, false);
        })
    };


export const getPAID =
  (email = "") =>
    (dispatch) => {
      vizPanelService.getPAID(email)
        .then((resp) => {
          dispatch({
            type: TYPE_ALLCARS.UPDATE_PAID,
            payload: resp
          });
        })
        .catch((err) => {
          showToastMessage(err?.error || err?.message, false);
        })
    };

export const showInspectorPDF = async (carId) => {
  return new Promise((resolve, reject) => {
    vizPanelService.getInspectionPDF(carId)
      .then((resp) => {
        if (resp?.pdfLink) {
          window.open(resp?.pdfLink, '_blank')

        }
        resolve(resp);
      })
      .catch((err) => {
        showToastMessage(err?.error || err?.message, false);
        reject(null);
      })
  });
}

export const setCarAppointmentId = (id) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.SET_CAR_APPOINTMENT_ID, payload: id });
}

export const setModal = (open) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.SET_MODAL, payload: open });
}

export const getPaiCarDetails = (status) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.SET_PAI_CAR_DETAIL, payload: status });
}

export const getRADetails = async (appointmentId) => {
  return new Promise((resolve, reject) => {
    vizPanelService.getRADetails(appointmentId)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        showToastMessage('RA details not found, check with Team Lead once', false);
        reject(null);
      })
  });
}

export const fetchSideBarFiltersData =
  () =>
    (dispatch) => {
      dispatch({ type: TYPE_LOADER.SHOW_LOADER });
      vizPanelService.fetchSideBarFiltersData()
        .then((resp) => {
          dispatch({ type: TYPE_LOADER.HIDE_LOADER });
          dispatch({
            type: TYPE_ALLCARS.FETCH_SIDE_BAR_FILTERS,
            payload: resp
          });
        })
        .catch((err) => {
          dispatch({ type: TYPE_LOADER.HIDE_LOADER });
          showToastMessage(err?.error || err?.message, false);

        })
    };

export const shareDealerCars = async (cars) => {
  return new Promise((resolve, reject) => {
    vizPanelService.shareCars(cars)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(null);
      })
  });
}

export const setFilterQueryParam = (filter) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.SET_FILTER_QUERY_PARAM, payload: filter });
}