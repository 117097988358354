import { TYPE_ALLCARS, TYPE_LOADER, TYPE_MY_DEALERS } from "../types";
import { vizPanelService } from "../../services";
import { getReqParams, showToastMessage } from "../../common/utils/utils";

export const fetchMyDealers =
  (params = {}) =>
    (dispatch) => {
      dispatch({ type: TYPE_MY_DEALERS.FETCH_LOADING_START });

      if (params.searchQuery) {
        params.page = 0;
      }
      let reqParams = getReqParams(params);
      vizPanelService.getMyDealers(reqParams)
        .then((resp) => {
          dispatch({ type: TYPE_MY_DEALERS.FETCH_LOADING_END });
          dispatch({

            type: TYPE_MY_DEALERS.FETCH_MY_DEALERS_SUCCESS,
            payload: {
              data: resp.data,
              page: resp.pagination.page,
              pageSize: resp.pagination.pageSize,
              totalElements: resp.pagination.totalElements,
              totalPages: resp.pagination.totalPages,
            },
          });
        })
        .catch((err) => {
          dispatch({ type: TYPE_MY_DEALERS.FETCH_LOADING_END });
          showToastMessage(err?.error || err?.message, false);
          dispatch({
            type: TYPE_MY_DEALERS.FETCH_MY_DEALERS_FAILURE,
            payload: err,
          });

        })
        .finally(() => {
          dispatch({ type: TYPE_MY_DEALERS.FETCH_LOADING_END });
        });
    };
export const updateDealersFilters = (filter) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.UPDATE_DEALERS_FILTERS, payload: filter });
}

export const setDealerID = (dealerId) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_DEALER_ID, payload: dealerId });
}

export const setSidebarDealerFilterPayloads =
  (data) =>
    (dispatch) => {
      dispatch({ type: TYPE_MY_DEALERS.SET_SIDEBAR__DEALER_FILTERS_PAYLOAD, payload: data });

    };

export const setSidebarDealerFilterApplied =
  (data) =>
    (dispatch) => {
      dispatch({ type: TYPE_MY_DEALERS.SET_SIDEBAR_DEALER_FILTERS_APPLIED, payload: data });

    };

export const refreshDealers = (param) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.REFRESH_DEALERS, payload: param });
}


export const markDealerContacted = (param) => (dispatch) => {
  let reqParams = getReqParams(param);
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });

  vizPanelService.markDealerContacted(reqParams)
    .then(() => {
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      dispatch(refreshDealers(true));
    })
    .catch((err) => {
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      showToastMessage(err?.error || err?.message, false);
    })
}

export const showMarkAsContacted = (data) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SHOW_CONTACTED_CONFIRM, payload: data });
};

export const addDealerContact = (params) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_START });

  return new Promise((resolve, reject) => {
    vizPanelService.addDealerContact(params)
      .then(() => {
        dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
        dispatch(refreshDealers(true));
        resolve();
      })
      .catch((err) => {
        dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
        showToastMessage(err?.error || err?.message, false);
        reject();
      })

  })
}

export const deleteDealerContact = (params) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_START });

  return new Promise((resolve, reject) => {
    vizPanelService.deleteDealerContact(params).then(() => {
      dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
      dispatch(refreshDealers(true));
      resolve();
    })
      .catch((err) => {
        dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
        showToastMessage(err?.error || err?.message, false);
        reject();
      })
  })

}

export const updateDealerContact = (params) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_START });

  return new Promise((resolve, reject) => {
    vizPanelService.updateDealerContact(params).then(() => {
      dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
      dispatch(refreshDealers(true));
      resolve();
    })
      .catch((err) => {
        dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
        showToastMessage(err?.error || err?.message, false);
        reject();
      })
  })
}

export const setDefaultDealerNumber = (params) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_START });

  return new Promise((resolve, reject) => {
    vizPanelService.setDefaultDealerNumber(params).then(() => {
      dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
      dispatch(refreshDealers(true));
      resolve();
    })
      .catch((err) => {
        dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
        showToastMessage(err?.error || err?.message, false);
        reject();
      })
  })
}

export const callDealer = (params) => (dispatch) => {
  dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_START });

  return new Promise((resolve, reject) => {
    vizPanelService.callDealer(params).then((resp) => {
      dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });
      resolve(resp);
    })
      .catch((err) => {
        dispatch({ type: TYPE_ALLCARS.FETCH_LOADING_END });

        showToastMessage(err?.error || err?.message, false);
        reject();
      })
  })
}

export const setOngoingCallStatus = (status) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_ONGOING_CALL_STATUS, payload: status });
}

export const fetchCallLogsData = (params) => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });

  return new Promise((resolve, reject) => {
    vizPanelService.callLogsData(params).then((resp) => {
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      resolve(resp);

    })
      .catch((err) => {
        dispatch({ type: TYPE_LOADER.HIDE_LOADER });
        showToastMessage(err?.error || err?.message, false);
        reject();
      })
  })
}

export const setCallEvent = (data) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_CURRENT_CALL_EVENT, payload: data });
}

export const getLastEventDetails = (params) => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });

  return new Promise((resolve, reject) => {
    vizPanelService.lastEventDetails(params).then((resp) => {
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      resolve(resp);

    })
      .catch((err) => {
        dispatch({ type: TYPE_LOADER.HIDE_LOADER });
        showToastMessage(err?.error || err?.message, false);
        reject();
      })
  })
}

export const setDealerFilterStatus = (status) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_DEALER_FILTER_STATUS, payload: status })
}

export const setDealerFilterQueryParam = (filter) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_DEALER_FILTER_QUERY_PARAM, payload: filter })
}

export const setEventSource = (status) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_EVENT_SOURCE, payload: status });
}

export const setCorrelationId = (correlationId) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_CORRELATION_ID, payload: correlationId });
}

export const setCallingView = (view) => (dispatch) => {
  dispatch({ type: TYPE_MY_DEALERS.SET_CALLING_VIEW, payload: view });
}











