export const TYPE_ALLCARS = {
  FETCH_LOADING_START: "FETCH_LOADING_START",
  FETCH_LOADING_END: "FETCH_LOADING_END",
  FETCH_ALLCARS_SUCCESS: "FETCH_ALLCARS_SUCCESS",
  FETCH_ALLCARS_FAILURE: "FETCH_ALLCARS_FAILURE",
  UPDATE_PAID: "UPDATE_PAID",
  UPDATE_FILTERS: "UPDATE_FILTERS",
  SHARE_CAR: "SHARE_CAR",
  SET_CAR_DETAILS_TAB: "SET_CAR_DETAILS_TAB",
  CAR_DETAILS: "CAR_DETAILS",
  SET_CAR_APPOINTMENT_ID: "SET_CAR_APPOINTMENT_ID",
  FETCH_SIDE_BAR_FILTERS: "FETCH_SIDE_BAR_FILTERS",
  SET_SIDEBAR_FILTERS_PAYLOAD: "SET_SIDEBAR_FILTERS_PAYLOAD",
  SET_SIDEBAR_FILTERS_APPLIED: "SET_SIDEBAR_FILTERS_APPLIED",
  CLEAR_CAR_DATA: "CLEAR_CAR_DATA",
  SET_MODAL: "SET_MODAL",
  SET_PAI_CAR_DETAIL: "SET_PAI_CAR_DETAIL",
  SET_FILTER_QUERY_PARAM: "SET_FILTER_QUERY_PARAM",
  SET_FILTER_STATUS: "SET_FILTER_STATUS",
  SET_CHECKED_ITEMS: "SET_CHECKED_ITEMS",
};
