import { USER_ROLE_KEYS } from '../../../../utils/constants/menu.constants';
import { ALL_CARS_OCB_LIVE_FILTER } from '../constants/filters.constants';


export const roleAccessMap = {
  [USER_ROLE_KEYS.C2B_VIZ_PANEL]: [ALL_CARS_OCB_LIVE_FILTER[0].id, ALL_CARS_OCB_LIVE_FILTER[1].id, ALL_CARS_OCB_LIVE_FILTER[2].id, ALL_CARS_OCB_LIVE_FILTER[3].id, ALL_CARS_OCB_LIVE_FILTER[4].id],
  [USER_ROLE_KEYS.C2B_VIZ_PANEL_TL]: [ALL_CARS_OCB_LIVE_FILTER[0].id, ALL_CARS_OCB_LIVE_FILTER[1].id, ALL_CARS_OCB_LIVE_FILTER[2].id, ALL_CARS_OCB_LIVE_FILTER[3].id, ALL_CARS_OCB_LIVE_FILTER[4].id],
  [USER_ROLE_KEYS.C2B_VIZ_PANEL_NO_LIVE_FEED]: [ALL_CARS_OCB_LIVE_FILTER[2].id, ALL_CARS_OCB_LIVE_FILTER[3].id, ALL_CARS_OCB_LIVE_FILTER[4].id],
};

export const hasCallingAccess = (userRoles) => {
  const requiredRoles = [USER_ROLE_KEYS.C2B_VIZ_PANEL_CALLING_VIEW]
  return requiredRoles.some(role => userRoles?.includes(role));
}
