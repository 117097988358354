import React, { useEffect, useState, useRef } from "react";
import styles from "./ColumnMenuRadioFilter.module.css";
import { Button } from "@progress/kendo-react-buttons";

export const ColumnMenuRadioFilter = ({
    filterData,
    columnMapKey,
    filterParams,
    setFilterParams,
    title,
    ...props
}) => {

    const [selectedValue, setSelectedValue] = useState(
        filterParams[columnMapKey] || ""
    );

    const dropdownRef = useRef(null);

    useEffect(() => {
        setSelectedValue(filterParams[columnMapKey] || "");
    }, [filterParams, columnMapKey]);

    const handleFilterChange = (e) => {
        setSelectedValue(e.target.value);
    };

    const handleClear = () => {
        setSelectedValue("");
        setFilterParams((prevParams) => ({
            ...prevParams,
            [columnMapKey]: ""
        }));
        props.onCloseMenu();
    };

    const handleApply = () => {
        setFilterParams((prevParams) => ({
            ...prevParams,
            [columnMapKey]: selectedValue
        }));
        props.onCloseMenu();
    };

    return (
        <div ref={dropdownRef} className={styles.dropdown}>
            <div className={styles.title}>
                {title}
            </div>
            {filterData[columnMapKey].map((item) => (
                <label key={item.value} className={styles.radioItem}>
                    <input
                        type="radio"
                        name={columnMapKey}
                        value={item.value}
                        checked={selectedValue === item.value}
                        onChange={handleFilterChange}
                        className={styles.radioInput}
                    />
                    {item.label}
                </label>
            ))}
            <div className={styles.buttons}>
                <Button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    style={{ width: '100px' }}
                    onClick={handleClear}
                >
                    Clear
                </Button>
                <Button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    style={{ width: '100px' }}                    
                    onClick={handleApply}
                    themeColor={"primary"}
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};
